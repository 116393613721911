import React from 'react'
import PropTypes from 'prop-types';
import ButtonLink from "../acbuttonlink"
import './textComponent.scss'
import SafeHTML from "../safehtml";


export default function TextComponent(props) {

    const { title, description, columnLayout, ctaLinks } = props;

    return (
        <section className={`ac-text ${columnLayout ? columnLayout.toLowerCase().replace(/ /g, "-") : ""}`}>
            <div className="container">
                <div className="ac-text-inner">
                    {title && <h2 className="ac-text-title has-top-line"> {title} </h2>}
                    {description && <div className="ac-text-content"><SafeHTML>
                        {description}
                    </SafeHTML>
                    </div>
                    }
                    {ctaLinks &&
                        <div>
                            {ctaLinks.map((cta, index) => {
                                return <ButtonLink key={index} {...cta}></ButtonLink>
                            })}
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}

TextComponent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.object,
    columnLayout: PropTypes.string,
    ctaLinks: PropTypes.array,
}